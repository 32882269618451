<template>
  <div
    class="index-box flex_row m_t_20"
    v-loading="loading">
    <div class="left-box">
      <Title
        title="时政要闻"
        :path="`/party/indexMore?id=${politicsCatalogue.id}&type=0`"></Title>
      <div>
        <router-link
          class="politics-item fs_16 p_lr_10 text_hidden pointer bg_fff"
          v-for="data in politicsData"
          :key="data.id"
          :to="{ path: '/party/content', query: { from: 0, cid: data.id } }">
          {{ data.title }}
        </router-link>
      </div>
    </div>
    <div class="right-box">
      <Title
        title="武侯动态"
        :path="`/party/indexMore?id=${trendsCatalogue.id}&type=0`"></Title>
      <div>
        <router-link
          class="trends-item flex_row bg_fff fs_16 m_b_10 pointer"
          v-for="data in trendsData"
          :key="data.id"
          :to="{ path: '/party/content', query: { from: 0, cid: data.id } }">
          <div class="cover-box">
            <img
              :src="data.coverUrl"
              alt="" />
          </div>
          <div class="title-box">
            {{ data.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { sessionGet, sessionSet } from '@/utils/local';
import Title from '../components/Title.vue';
import {
  getPartyResources,
  applyPartyMember,
  turnOffPrompts,
  applyPartyMemberRecord,
  getContents,
} from '@/api/party/index';
export default {
  name: 'Index',
  components: {
    Title,
  },
  data() {
    return {
      catalogues: [],
      // 时政
      politicsCatalogue: {},
      politicsData: [],
      // 动态
      trendsCatalogue: {},
      trendsData: [],
      loading: false,
    };
  },
  async mounted() {
    let userInfo = await sessionGet('userInfo');
    let menus = sessionGet('SystemMenus');
    if (!menus) {
      const { data } = await getContents();
      menus = data;
    }
    menus.forEach((item) => {
      if (item.name == '首页') {
        this.catalogues = item.children;
      }
    });
    // /*
    //   isTips = true && userInfo.isTips = 1 提示
    //   有局端管理员、校端管理员、党员身份 isTips = false
    //   点击申请 isTips = false
    //   点击关闭提示 userInfo.isTips = 0
    //   点击关闭 isTips = false
    // */
    // // 是否是第一次登录
    // let isTips = true;
    // // let isTips = sessionGet('isTips') == null ? true : sessionGet('isTips');
    // // 是否有身份
    // let role = [
    //   'partyBuildingMembers',
    //   'partyBuildingSchoolAdministrator',
    //   'partyBuildingAdministrator',
    // ];
    // role.forEach((item) => {
    //   console.log(item, userInfo.role_name.indexOf(item));
    //   if (userInfo.role_name.indexOf(item) >= 0) {
    //     isTips = false;
    //   }
    // });
    // console.log(isTips);
    // // 是否已经申请
    // await applyPartyMemberRecord({
    //   userId: userInfo.user_id,
    //   isExamine: 2,
    // }).then((res) => {
    //   if (res.data.length > 0) {
    //     isTips = false;
    //     sessionSet('isTips', false);
    //   }
    // });
    // //提示
    // if (isTips && userInfo.isTips) {
    //   console.log(isTips)
    //   console.log(userInfo)
    //   this.$confirm('您还不是党员，是否申请成为党员?', '提示', {
    //     confirmButtonText: '申请',
    //     cancelButtonText: '关闭提示',
    //     type: 'warning',
    //     distinguishCancelAndClose: true,
    //     beforeClose: (action, instance, done) => {
    //       if (action == 'close') {
    //         sessionSet('isTips', false);
    //       }
    //       done();
    //     },
    //   })
    //     .then(() => {
    //       let params = {
    //         isExamine: 2,
    //         userId: userInfo.user_id,
    //       };
    //       applyPartyMember(params)
    //         .then(() => {
    //           this.$message.success('已提交申请');
    //         })
    //         .catch(() => {
    //           this.$message.error('申请失败，请稍后重试');
    //         });
    //       sessionSet('isTips', false);
    //     })
    //     .catch(() => {
    //       let params = {
    //         isTips: 0,
    //         id: userInfo.user_id,
    //       };
    //       turnOffPrompts(params)
    //         .then(() => {
    //           this.$message.success('已关闭提示');
    //         })
    //         .catch(() => {
    //           this.$message.error('关闭提示失败');
    //         });
    //     });
    // }

    this.catalogues.forEach(async (item) => {
      this.loading = true;
      if (item.name == '时政要闻') {
        this.politicsCatalogue = item;
        this.politicsData = await this.getResourceData({
          contentId: item.id,
          name: item.name,
          current: 1,
          size: 10,
        });
      } else {
        this.trendsCatalogue = item;
        this.trendsData = await this.getResourceData({
          contentId: item.id,
          name: item.name,
          current: 1,
          size: 10,
        });
      }
      this.loading = false;
    });
  },
  methods: {
    // 获取数据
    async getResourceData(params) {
      let res = await getPartyResources(params);
      return res.data.records;
    },
  },
};
</script>

<style scoped>
.index-box {
  width: 100%;
  justify-content: space-between;
}
.left-box {
  width: 50%;
  height: 300px;
}
.right-box {
  width: 45%;
  height: 300px;
}
.politics-item {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.politics-item::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #dd2622;
  border-radius: 50%;
  display: inline-block;
}

.trends-item {
  width: 100%;
  height: 88px;
}
.cover-box {
  width: 156px;
  height: 100%;
  flex-shrink: 0;
}
.cover-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title-box {
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 21px;
  margin-left: 20px;
}
</style>
