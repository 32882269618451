<template>
  <div class="title">
    <div>
      <el-divider direction="vertical" class="v-divide"></el-divider>
      {{ title }}
    </div>
    <div v-if="path != ''">
      <el-button class="btn" @click="$router.push(path)">更多 </el-button>
    </div>
    <el-divider class="divide"></el-divider>
  </div>
</template>

<script>
export default {
  name: "Title",
  data: () => {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: YouSheBiaoTiHei-2;
  src: url("../../../assets/fonts/YouSheBiaoTiHei-2.ttf");
}

@font-face {
  font-family: PingFang-SC;
  src: url("../../../assets/fonts/苹方黑体-中粗-简.ttf");
}

.title {
  font-family: YouSheBiaoTiHei-2;
  color: #dd2622;
  font-size: 26px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }

  .btn {
    width: 50px;
    height: 24px;
    background: #dd2622;
    padding: 0;
    border-radius: 4px;
    font-size: 15px;

    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    line-height: 15px;
  }
  .v-divide {
    background-color: #dd2622;
    padding: 0;
    width: 3px;
    height: 15px;
    margin: 0 10px 0 0;
  }
  .divide {
    margin-top: 1px;
    background-color: #dd2622;
    height: 2px;
    width: 100%;
    margin-bottom: 13px;
  }
}
</style>
